import React, { memo } from 'react'
import DSELayout from '../components/DSE/DSELayout/DSELayout';
import Resources from '../components/DSE/Resources/Resources';

const ResourcesPage = props => {
  return (
    <React.Fragment>
      <DSELayout
        canonicalURL='https://npcfacts.com/resources'
        title='Nasopharyngeal cancer (NPC) resources'
        pageTitle='Nasopharyngeal cancer (NPC) resources'
        description='Nasopharyngeal cancer resources and support for you and your family'
        keyWords='Nasopharyngeal carcinoma; NPC treatments; NPC outcomes'
        >
        <Resources />
      </DSELayout>
    </React.Fragment>
  )
}

export default memo(ResourcesPage)
