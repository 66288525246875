import React from "react";
import DSEWrapper from "../DSEWrapper/DSEWrapper";
import Title from "../title/title";
import IconTitleText from "../IconTitleText/IconTitleText";
import DseCta from "../DSECta/DSECta";

const Resources = () => {
  return (
    <section className="dse-resources" id="resources">
      <DSEWrapper className="dse-resources__title" fullWidth={true}>
        <Title text="RESOURCES" isH1={true}/>
      </DSEWrapper>
      <DSEWrapper>
        <div className="dse-resources--main">
          <div className="dse-resources__download">
            <p className="dse-resources__highlight dse-highlight-text">
              Read a helpful brochure about understanding nasopharyngeal cancer
            </p>
            <DseCta
              className="dse-resources__cta"
              type="link"
              href="/pdf/npc-dse-brochure.pdf"
              target="_blank"
              text="DOWNLOAD"
            />
          </div>
          <div className="dse-resources__download">
            <p className="dse-resources__highlight dse-highlight-text">
              Get a guide to help you talk with your doctor about nasopharyngeal
              cancer
            </p>
            <DseCta
              className="dse-resources__cta"
              type="link"
              href="/pdf/npc-doctor-discussion-guide.pdf"
              target="_blank"
              text="DOWNLOAD"
            />
          </div>
        </div>
        <p className="dse-resources__instructions">
          Below is a list of patient advocacy groups that are committed to
          providing emotional support and medical information to help guide you on your nasopharyngeal cancer journey.
        </p>
        <div className="dse-resources__list">
          <IconTitleText title="SUPPORT FOR HEAD AND NECK CANCER">
            <ul>
              <li><a href="https://www.headandneck.org" target="_blank">Head and Neck Cancer Alliance</a></li>
              <li><a href="https://spohnc.org/" target="_blank">Support for People With Oral Head and Neck Cancer (SPOHNC)</a></li>
              <li><a href="https://thancfoundation.org/" target="_blank">Thyroid Head and Neck Cancer Foundation (THANC)</a></li>
            </ul>
          </IconTitleText>
          <IconTitleText title="SUPPORT FOR ALL CANCERS">
            <ul>
              <li><a href="https://www.cancercare.org/" target="_blank">Cancer Care</a></li>
              <li><a href="https://www.cancersupportcommunity.org/" target="_blank">Cancer Support Community</a></li>
              <li><a href="https://www.cancer.org/" target="_blank">American Cancer Society</a></li>
              <li><a href="https://rarediseases.org/" target="_blank">National Organization for Rare Disorders</a></li>
              <li><a href="https://www.nccn.org/patientresources/patient-resources/nccn-foundation" target="_blank">National Comprehensive Cancer Network (NCCN) Foundation</a></li>
              <li><a href="https://triagecancer.org/" target="_blank">Triage Cancer</a></li>
            </ul>
          </IconTitleText>
        </div>
        <div className="dse-resources__join">
          <p className="dse-resources__highlight dse-highlight-text">
            Get educational tools and resources to help support you on your NPC
            journey
          </p>
          <DseCta
            className="dse-resources__cta"
            type="link"
            href="/join-NPCFacts-community"
            text="JOIN THE NPC FACTS COMMUNITY"
          />
        </div>
      </DSEWrapper>
    </section>
  );
};

export default Resources;
