import React from "react";

const IconTitleText = ({ className = '', icon, iconAlt = 'Icon', title, children }) => {
  return (
    <div className={`dse-itt ${className}`}>
      {icon && 
        <div className="dse-itt__icon">
          <img src={icon} alt={iconAlt}/>
        </div>
      }
      {title && 
        <div className="dse-itt__title">
          <h3 className="dse-heading3" dangerouslySetInnerHTML={{__html: title}}></h3>
        </div>
      }
      { children &&
        <div className="dse-itt__text">
          {children}
        </div>
      }
    </div>
  )
};

export default IconTitleText;